var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showButtons.edit)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":"","to":{ name: _vm.link, params: { id: _vm.id }}}},on),[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.customActionEdit(_vm.id)}}},[_vm._v("mdi-pencil")])],1)]}}],null,false,2410732216)},[_c('span',[_vm._v(_vm._s(_vm.$t('editar')))])]):_vm._e(),(_vm.showButtons.editarRevisao)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":"","to":{ name: _vm.link, params: { id: _vm.id }}}},on),[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.customActionEdit(_vm.id)}}},[_vm._v("mdi-pencil")])],1)]}}],null,false,2410732216)},[_c('span',[_vm._v(_vm._s(_vm.$t('gerarRevisao')))])]):_vm._e(),(_vm.showButtons.delete)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.confirmDelete(_vm.id)}}},[_vm._v("mdi-delete")])],1)]}}],null,false,2109306662)},[_c('span',[_vm._v(_vm._s(_vm.$t('excluir')))])]):_vm._e(),(_vm.showButtons.cancel)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.confirmCancel(_vm.id)}}},[_vm._v("mdi-flask-empty-off")])],1)]}}],null,false,472986665)},[_c('span',[_vm._v(_vm._s(_vm.$t('inativar')))])]):_vm._e(),(_vm.showButtons.active)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.confirmActive(_vm.id)}}},[_vm._v("mdi-account-reactivate-outline")])],1)]}}],null,false,1012834053)},[_c('span',[_vm._v(_vm._s(_vm.$t('ativar')))])]):_vm._e(),(_vm.showButtons.approve)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.confirmApprove(_vm.id)}}},[_vm._v("mdi-account-check-outline")])],1)]}}],null,false,2741790990)},[_c('span',[_vm._v(_vm._s(_vm.$t('aprovar')))])]):_vm._e(),(_vm.showButtons.resetarSenha)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.confirmResetarSenha(_vm.id)}}},[_vm._v("mdi-lock-reset")])],1)]}}],null,false,491779138)},[_c('span',[_vm._v(_vm._s(_vm.$t('resetarSenha')))])]):_vm._e(),(_vm.showButtons.detalhes)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.showDetalhes(_vm.id)}}},[_vm._v("mdi-text-search")])],1)]}}],null,false,3314344559)},[_c('span',[_vm._v(_vm._s(_vm.$t('visualizarDetalhes')))])]):_vm._e(),(_vm.showButtons.cancelarCotacao)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.confirmCancelCotacao(_vm.id)}}},[_vm._v("mdi-window-close")])],1)]}}],null,false,930278179)},[_c('span',[_vm._v(_vm._s(_vm.$t('cancelarCotacao')))])]):_vm._e(),(_vm.showButtons.downloadCotacao)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.confirmDownloadCotacao(_vm.id)}}},[_vm._v("mdi-file-download-outline")])],1)]}}],null,false,520407188)},[_c('span',[_vm._v(_vm._s(_vm.$t('downloadCotacao')))])]):_vm._e(),(_vm.showButtons.visualizarHistorico)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.visualizarHistorico(_vm.id)}}},[_vm._v("mdi-history")])],1)]}}],null,false,80785532)},[_c('span',[_vm._v(_vm._s(_vm.$t('historicoAlteracoes')))])]):_vm._e(),(_vm.showButtons.criarPedido)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.confirmCriarPedido(_vm.id)}}},[_vm._v("mdi-folder-plus-outline")])],1)]}}],null,false,1118672850)},[_c('span',[_vm._v(_vm._s(_vm.$t('criarPedido')))])]):_vm._e(),(_vm.showButtons.validar)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.confirmValidar(_vm.id)}}},[_vm._v("mdi-playlist-check")])],1)]}}],null,false,3861162990)},[_c('span',[_vm._v(_vm._s(_vm.$t('validar')))])]):_vm._e(),(_vm.showButtons.clonar)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.confirmClone(_vm.id)}}},[_vm._v("mdi-content-duplicate")])],1)]}}],null,false,1896129822)},[_c('span',[_vm._v(_vm._s(_vm.$t('clonar')))])]):_vm._e(),(_vm.showButtons.voltarPedido)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.confirmVoltarPedido(_vm.id)}}},[_vm._v("mdi-backup-restore")])],1)]}}],null,false,3472960876)},[_c('span',[_vm._v(_vm._s(_vm.$t('voltarPedidoIntegracaoTotvs')))])]):_vm._e(),(_vm.showButtons.consultarLog)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.consultarLog(_vm.id)}}},[_vm._v("mdi-clipboard-text-clock-outline")])],1)]}}],null,false,818947243)},[_c('span',[_vm._v(_vm._s(_vm.$t('consultarLog')))])]):_vm._e(),(_vm.showButtons.consultarAnexos)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.consultarAnexos(_vm.id)}}},[_vm._v("mdi-paperclip")])],1)]}}],null,false,2102608341)},[_c('span',[_vm._v(_vm._s(_vm.$t('verificarAnexo')))])]):_vm._e(),(_vm.showButtons.copiar)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.copiar(_vm.id)}}},[_vm._v("mdi-content-copy")])],1)]}}],null,false,2288474323)},[_c('span',[_vm._v(_vm._s(_vm.$t('copiarLink')))])]):_vm._e(),(_vm.showButtons.cancelarPedido)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.confirmCancelPedido(_vm.id)}}},[_vm._v("mdi-window-close")])],1)]}}],null,false,175537668)},[_c('span',[_vm._v(_vm._s(_vm.$t('cancelarPedido')))])]):_vm._e(),_c('ActionResetUsuarioSenha',{attrs:{"showDialogResetUsuarioSenha":_vm.dialogResetarUsuarioSenha.show,"id":_vm.dialogResetarUsuarioSenha.id},on:{"update:showDialogResetUsuarioSenha":function($event){return _vm.$set(_vm.dialogResetarUsuarioSenha, "show", $event)},"update:show-dialog-reset-usuario-senha":function($event){return _vm.$set(_vm.dialogResetarUsuarioSenha, "show", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }