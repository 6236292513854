<template>
    <div class="text-center">
		<v-dialog
			v-model="showDialogResetUsuarioSenha"
			transition="dialog-top-transition"
			persistent
			width="500"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
		
			<v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
					Reset de Senha do Usuário
				</v-card-title>

				<v-card-text style="margin-top: 20px;">
        
					<v-row>
						<v-col cols="12"
							lg="12"
							md="12"
							sm="12"
						>
							<label>Sua senha de administrador</label>
							<v-text-field
								:append-icon="showPasswordAdmin ? 'mdi-eye' : 'mdi-eye-off'"
								:type="showPasswordAdmin ? 'text' : 'password'"
								v-model="passwordAdmin"
								autocomplete="new-password"
								hint="Senha do Administrador"
								:rules=[validations.required]
								single-line
								outlined
								@click:append="showPasswordAdmin = !showPasswordAdmin"
							>
								<template v-slot:progress>
									<v-progress-linear
										:value="progress"
										:color="color"
										absolute
										height="7"
									>
									</v-progress-linear>
								</template>
							</v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<hr />
						</v-col>
					</v-row>
					<v-row>
						<v-col 
							cols="12"
							lg="12"
							md="12"
							sm="12"
						>
							<v-btn
								class="mx-2"
								outlined
								@click="generatePassword()"
								style="width: 100%"
							>
								Gerar Senha
							</v-btn>
						</v-col>
					</v-row>
					<v-row>
						<v-col 
							cols="12"
							lg="12"
							md="12"
							sm="12"
						>
							<label>Nova senha</label>
							<v-text-field
								:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
								:type="showPassword ? 'text' : 'password'"
								v-model="password"
								autocomplete="new-password"
								:rules=[validations.password]
								hint="Password must be at least 8 characters, 1 uppercase and 1 lowercase letter"
								single-line
								outlined
								loading
								@click:append="showPassword = !showPassword"
							>
								<template v-slot:progress>
									<v-progress-linear
										:value="progress"
										:color="color"
										absolute
										height="7"
									>
									</v-progress-linear>
								</template>
							</v-text-field>
						</v-col>
					</v-row>
					<v-row>

						<v-col cols="12"
							lg="12"
							md="12"
							sm="12"
						>
							<label>Confirmar nova senha</label>
							<v-text-field
								:append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
								:type="showConfirmPassword ? 'text' : 'password'"
								v-model="confirmPassword"
								autocomplete="new-password"
								:rules=[validations.samePassword(password,confirmPassword)]
								single-line
								outlined
								@click:append="showConfirmPassword = !showConfirmPassword"
							></v-text-field>
						</v-col>
					</v-row>
					

				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonCancel"
							outlined
							@click="cancel()"
						>
							Cancelar
						</v-btn>

						<v-btn
							class="mx-2"
							outlined
							@click="saveNewPassword()"
						>
							Confirmar
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>
		
		<DialogMessage 
			v-on:methodOKToCall="dialogMessage.methodOK"
			:showDialogMessage.sync="dialogMessage.show"
			:headerTitle="dialogMessage.headerText"
			:bodyText="dialogMessage.bodyText"
		/>
	</div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import { required, validPassword } from "@/utilities/Rules";
    import DialogMessage from "@/components/Layout/DialogMessage";
    import Helpers from '@/utilities/Helpers';

	export default {

        mixins: [Helpers],

        components: {
            DialogMessage
        },

        props: {

			showDialogResetUsuarioSenha: {
				default: false
			},

            id: {
                type: Number,
                default : 0
            },
		},

        data: () => ({

            loading: false,
            passwordAdmin: "",
            password: "",
            confirmPassword: "",
            showPasswordAdmin: false,
            showPassword: false,
            showConfirmPassword: false,

            validations: {
                required: required,
                password: validPassword,
                samePassword (password, confirmPassword) {
                    if (password !== confirmPassword) {
                        return "Senhas não conferem!"
                    }
                    else
                        return true;
                }
            },

            dialogMessage: {
                show: false,
                headerText: "",
                bodyText: "",
                methodOK: ( () => {} ),
            },
        }),

        watch: {
            async "id"() {
				this.clearFields();
            }
        },

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            progress () {
                return Math.min(100, this.password.length * 13)
            },

            color () {
                return ['error', 'warning', 'success'][Math.floor(this.progress / 40)]
            },
        },

        methods: {

            validForm() {

                var isValidPass = true;

                if (this.password != "" || this.confirmPassword != "")
                {
                    isValidPass = validPassword(this.password) === true && this.password === this.confirmPassword;
                }

                var isValidFrm = isValidPass === true;

                return isValidFrm;
            },

			async cancel() {
				window.location.reload();
			},

			async clearFields(){
				this.passwordAdmin = "";
				this.password = "";
				this.confirmPassword = "";
			},
			
			async generatePassword() {
				var charsNumbers = "012345678901234567890";
				var charsUpperCase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
				var charsLowerCase = "abcdefghijklmnopqrstuvwxyz";
				var charsSpecialCharacter = "!@#$%^&*()";
				var passwordLength = 8;
				var password = "";

				for (var i = 0; i <= passwordLength; i++) {
					var randomNumber = Math.floor(Math.random() * charsNumbers.length);

					var randomUpperCase = Math.floor(Math.random() * charsUpperCase.length);
					var randomLowerCase = Math.floor(Math.random() * charsLowerCase.length);
					var randomSpecialCharacter = Math.floor(Math.random() * charsSpecialCharacter.length);

					password += charsNumbers.substring(randomNumber, randomNumber +1);
					password += charsUpperCase.substring(randomUpperCase, randomUpperCase +1);
					password += charsLowerCase.substring(randomLowerCase, randomLowerCase +1);
					password += charsSpecialCharacter.substring(randomSpecialCharacter, randomSpecialCharacter +1);
				}

				this.password = password;
				this.confirmPassword = password;
			},

            async saveNewPassword() {

                if (this.validForm() === false) {
                    this.showToast("error", this.$t('aviso'), this.$t('inconsistenciaFormulario'));
                    return false;
                }
                else {

                    this.loading = true;
                    this.showLoading();

                    let requestResetUsuarioSenha = {
                        idUsuario: this.id,
						email: this.userLoggedGetters.email, 
                        currentPassword: this.passwordAdmin,
                        password: this.password,
                        confirmPassword: this.confirmPassword
                    }

                    const result = await this.$store.dispatch("usuarioModule/ResetUsuarioSenha", requestResetUsuarioSenha);

                    if (result.success === true) {
                        
						this.dialogMessage = {
							show: true,
							headerText: 'Success',
							bodyText: "O usuário irá receber um e-mail com a nova senha.",
							methodOK: (() => { this.cancel() }),
						};
					}
                    else {
                        this.showToast("error", this.$t('aviso'), result.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                }
            }
        }
    };
</script>
